<template>
    <div class="container justify-content-center mt-2">
        <div class="form-group">
            <div class="col-sm-4 mb-4">
                <label for="password"><h4>Новый пароль</h4></label>
                <input type="password" class="form-control" id="password" v-model="password"
                       placeholder="Новый пароль">
            </div>
        </div>
        <div class="form-group">
            <div class="col-sm-4 mb-4">
                <label for="password_repeat"><h4>Повторите пароль</h4></label>
                <input type="password" class="form-control" id="password_repeat" v-model="password_repeat"
                       placeholder="Повторите пароль">
            </div>
        </div>

        <div class="form-group">
            <div class="col-xs-12">
                <button class="btn btn-lg btn-success" type="submit" @click="changePassword">Сохранить</button>
            </div>
        </div>

        <div class="mt-2" v-if="error">
            {{error}}
        </div>

        <div v-if="loading" class="mt-5">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>

    </div>
</template>


<script>

    import httpClient from "../services/http.service";
    import {getCookie, setCookie} from "@/utils/helpers/cookies.helpers";

    export default {
        data() {
            return {
                password: '',
                password_repeat: '',
                error: '',
                loading: false
            }
        },
        methods: {
            async changePassword() {
                if (this.password === this.password_repeat && this.password) {
                    this.loading = true
                    const {
                        status,
                        data
                    } = await httpClient.put('api/user/change-password', {
                        user_id: getCookie('USER_ID'),
                        password: this.password,
                        password_repeat: this.password_repeat
                    });

                    if (status === 200) {
                        console.log(data)
                        setCookie('PASSWORD_CHANGED', data._user.password_changed);
                        this.$message({title: 'Изменение пароля', text: 'Успешное изменение пароля'})
                        this.$router.push({name: 'enrollee-eds'})
                    }
                    this.loading = false

                } else {
                    this.error = 'Введите пароль или пароли не сопадают';
                }


            }
        }
    }
</script>


<style>
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #555;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }


</style>